import _ from "lodash";
import axios from "axios";
import { toRefs, reactive } from "vue";

import apiCancel from "@/utils/api-cancel";
let postal_code_post = new apiCancel();

export default ({ address, address_form }) => {
    let state = reactive({
        /* 加载状态 */
        postal_code_loading: false,
        /* 邮编数据 */
        postal_code_datas: [],
        /* 城市列表 */
        cities: []
    })

    var analyzePostalCode = _.debounce(function ({country_code,postal_code}) {
        if (!postal_code) {
            return
        }

        state.postal_code_loading = true;
        if (postal_code_post.apiPost) {
            postal_code_post.cancel()
        }

        postal_code_post.apiPost = axios.get(`/address/analyze_postalcode/${country_code}/${postal_code.split('-')[0]}`, {
            cancelToken: postal_code_post.get_token()
        }).then(res => {
            state.postal_code_loading = false;
            state.postal_code_datas = [];

            if (res.data.code === 0) {
                state.cities = [];

                if (res.data.data.length > 1) {
                    for (var i = 0; i < res.data.data.length; i++) {
                        var item = res.data.data[i];

                        state.postal_code_datas.push({
                            value: `${item.postalcode}-${i}`,
                            label: `${item.postalcode} - ${item.city}, ${item.statecode}, ${item.address}`,
                            postalcode: postal_code,
                            city: item.city,
                            statecode: item.statecode,
                            address: item.address,
                            country_code: item.country_code
                        });
                    }
                } else if (res.data.data.length === 1) {
                    address.value.postal_code = postal_code;
                    address.value.city = res.data.data[0].city;
                    address.value.state_code = res.data.data[0].statecode;
                    res.data.data[0].country_code && (address.value.country_code = res.data.data[0].country_code)
                    res.data.data[0].address && (address.value.address1 = res.data.data[0].address)

                    address_form.value.clearValidate()
                }
            }
        }).catch(() => {
            state.postal_code_loading = false
        }).finally(() => {
            postal_code_post.new_token();
        })
    }, 100);
    return {
        ...toRefs(state),
        analyzePostalCode
    }
}